import type { BaseData, IMainSubStructureModel, NormalProgrammeParams } from '../../utils';
import { request, SearchListModal, RenderByPermission } from '../../utils';
import { action, observable } from 'mobx';
import { Button, Modal, message } from 'antd';
import React from 'react';
import { API } from './api';
import AnchorEditModel from './modal/anchorEdit/model';
import AnchorDetailModel from './modal/anchorDetail/model';

const { confirm } = Modal;

export default class Model {
  constructor() {
    this.pageStore.grid.onQuery();
  }

  public anchorEditStore = new AnchorEditModel(this);

  public anchorDetailStore = new AnchorDetailModel(this);

  @observable public filterSet: Partial<NormalProgrammeParams> = {
    filterItems: [
      {
        labelWidth: 60,
        type: 'input',
        field: 'id',
        label: '用户ID',
        placeholder: '请输入',
      },
      {
        type: 'input',
        field: 'wechatGroupNick',
        label: '群昵称',
        placeholder: '请输入',
      },
      {
        type: 'input',
        field: 'name',
        label: '姓名',
        placeholder: '请输入',
      },
      {
        type: 'input',
        field: 'mobile',
        label: '手机号',
        placeholder: '请输入',
      },
      {
        type: 'input',
        field: 'targetBase',
        label: '期望工作地',
        placeholder: '请输入',
      },
      {
        type: 'input',
        field: 'groupName',
        label: '所属群',
        placeholder: '请输入',
      },
      {
        type: 'dateRange',
        field: 'createTime',
        label: '录入时间',
      },
    ],
  };

  public grid: IMainSubStructureModel = {
    collectData: [
      {
        name: '总量',
        value: 0,
      },
    ],
    buttons: [
      {
        text: '删除',
        permissionId: '4',
        handleClick: () => {
          const ids = Array.from(this.pageStore.grid.gridModel.selectedIds);
          if (!ids.length) {
            message.warning('请选择要删除的主播');
            return;
          }
          confirm({
            title: '删除提示',
            content: '是否删除这些主播吗？',
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
              this.requestDelete(ids.join(','));
            },
          });
        },
      },
      {
        permissionId: '2',
        text: '新增',
        handleClick: () => {
          this.anchorEditStore.onShow();
        },
      },
    ],
    grid: {
      columns: [
        {
          key: 'userId',
          name: '操作',
          width: 200,
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');
            return (
              <div>
                <RenderByPermission permissionId={`${pageId}_21`}>
                  <Button
                    onClick={() => {
                      this.anchorDetailStore.onShow(row);
                    }}
                    type="link"
                  >
                    详情
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_3`}>
                  <Button
                    onClick={() => {
                      this.anchorEditStore.onShow(row, 'edit');
                    }}
                    type="link"
                  >
                    编辑
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_4`}>
                  <Button
                    onClick={() => {
                      this.onDelete(row);
                    }}
                    type="link"
                  >
                    删除
                  </Button>
                </RenderByPermission>
              </div>
            );
          },
        },
        {
          key: 'id',
          name: '用户编号',
        },
        {
          key: 'name',
          name: '主播姓名',
        },
        {
          key: 'groupIndo',
          name: '所属群组/昵称',
          formatter: ({ row }) => {
            const title = row?.groupInfo?.map((item) => `${item.anchorGroupName}(${item.anchorGroupNickName})`);
            return row?.groupInfo?.length ? <div title={title.join(' / ')}>{title.join(' / ')}</div> : '无';
          },
        },
        {
          key: 'opusCount',
          name: '作品',
          formatter: ({ row }) => `${row.children?.length | 0}个`,
        },
        {
          key: 'sysUserName',
          name: '录入人',
        },
        {
          key: 'mobile',
          name: '手机号',
        },
        {
          key: 'targetBase',
          name: '期望工作地',
        },
        {
          key: 'createTime',
          name: '录入时间',
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      showPager: true,
      showGridOrderNo: true,
    },
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    hiddenSubTable: true,
    api: {
      onQuery: (params) => {
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };

        for (const k in postParams) {
          if (Array.isArray(postParams[k])) {
            postParams[k] = postParams[k].toString();
          }
        }
        if (postParams.createTime) {
          postParams.createTimeBegin = postParams.createTime.split(',')[0];
          postParams.createTimeEnd = postParams.createTime.split(',')[1];
          delete postParams.createTime;
        }
        return request<BaseData<any>>({
          url: API.queryAnchorPage,
          method: 'POST',
          data: { ...postParams },
        }).then((res) => {
          this.pageStore.grid.collectData = [
            {
              name: '总量',
              value: res?.data?.total,
            },
          ];
          return res;
        });
      },
    },
  };

  public pageStore = new SearchListModal({
    programme: this.filterSet,
    grid: this.grid,
  });

  // 删除表格项
  private onDelete = (row) => {
    confirm({
      title: '删除提示',
      content: `是否删除「${row.name}」？`,
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        this.requestDelete(row.id);
      },
    });
  };

  // 请求删除接口
  private requestDelete = async (ids) => {
    const result = await request<{ code: number; msg?: string; data: any }>({
      url: API.deleteAnchor,
      method: 'POST',
      data: { ids },
    });
    message.success(result.msg || '删除成功');
    this.pageStore.grid.onQuery();
  };
}
