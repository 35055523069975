import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import React from 'react';
import styles from './index.less';
import { Image, Space } from 'antd';
import { toJS } from 'mobx';
import { UnorderedListOutlined, EyeOutlined, DragOutlined, DeleteOutlined } from '@ant-design/icons';
// 单个可排序的图片项
const DragHandle = SortableHandle((props: any) => (
  <div className={styles.drag}>
    <DragOutlined className={styles.closeCircleOutlined} />
  </div>
));
const SortableItem = SortableElement(({ type, file, removeFile, ...res }) => (
  // <div
  //   style={{zIndex: 9999}}
  //   className={styles.row}
  // >
  //   <DragHandle
  //     // @ts-ignore
  //     url={type === 1 ? file.thumbUrl : file.url}
  //   />
  //   <DragOutlined
  //     onClick={(e) => {
  //       removeFile(file.url);
  //     }}
  //     className={styles.closeCircleOutlined}
  //     style={{fontSize: 14}}
  //   />
  // </div>
  <div
    style={{ zIndex: 9999 }}
    className={styles.imgCard}
  >
    <DragHandle />
    <Image
      onDragStart={(e) => {
        e.preventDefault();
      }}
      preview={{
        mask: (
          <Space className={styles.asdasdas}>
            <div
              onClick={() => {
                console.log('asdasdasasdas');
              }}
            >
              <EyeOutlined className={styles.icon} />
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                removeFile(file.url);
              }}
            >
              <DeleteOutlined className={styles.icon} />
            </div>
          </Space>
        ),
        ...(type === 1
          ? {
              destroyOnClose: true,
              imageRender: () => (
                <video
                  muted
                  style={{
                    width: '60%',
                    height: '50%',
                  }}
                  controls
                  src={file.url}
                />
              ),
              toolbarRender: () => null,
            }
          : {}),
      }}
      className={styles.img}
      src={type === 1 ? file.thumbUrl : file.url}
    />
  </div>
));
// 图片列表容器
const SortableList = SortableContainer(({ type, items, removeFile }) => (
  <div className={styles.cardList}>
    {items.map((file: any, index) => (
      <SortableItem
        key={`item-${file?.url}`}
        index={index}
        // @ts-ignore
        file={file}
        type={type}
        removeFile={removeFile}
      />
    ))}
  </div>
));
export { SortableList };
