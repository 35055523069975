import type { FormInstance } from 'antd';
import { message } from 'antd';
import type { BaseData } from '../../../../utils';
import { request } from '../../../../utils';
import { action, observable } from 'mobx';
import { API } from '../../api';

interface LabelAndValue {
  label: string;
  value: string;
  disabled?: boolean;
}

interface resultValue<T> {
  code: number;
  data?: T;
  msg?: string;
}

export default class SelectGroupModel {
  constructor({ parent }) {
    this.parent = parent;
  }

  @observable public visible = false;

  @observable public saveLoading = false; // 保存状态

  @observable public ref: FormInstance;

  @observable public id = null; // 用户id

  @observable public parent = null;

  @observable public groupInfoOptions = [];

  @action
  public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };

  @action
  public onShow = (data?: any) => {
    this.visible = true;
    this.queryGroupList();
  };

  @action
  public onClose = () => {
    this.ref.resetFields();
    this.visible = false;
  };

  @action
  public queryGroupList = async (groupName = '') => {
    const req = await request<BaseData<any>>({
      url: API.queryGroupList,
      method: 'POST',
      data: {
        pageNum: 1,
        pageSize: 999,
        groupName,
        groupType: 0,
      },
    });
    this.groupInfoOptions = (req?.data?.list || []).map((item) => ({
      ...item,
      label: `${item.groupName}（${item.userCount || 0}人）`,
    }));
  };

  public getOptions = (): any[] => {
    const anchorGroupIdList = this.parent?.groupInfo?.map((item) => item.anchorGroupId);
    return this.groupInfoOptions?.filter((item) => !anchorGroupIdList.includes(item.id));
  };

  @action
  public onSave = async () => {
    const values = await this.ref.validateFields();
    this.saveLoading = true;
    const anchorGroupName = this.groupInfoOptions?.filter((item) => item.id === values.anchorGroupId)[0]?.groupName || '';
    const postParams = {
      ...values,
      anchorGroupName,
    };
    setTimeout(() => {
      this.parent?.addGroup(postParams);
      this.saveLoading = false;
      this.onClose();
    }, 0);
  };
}
